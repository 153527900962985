import { useMemo, useSyncExternalStore } from 'react';
import { CookieStore, LocalStorageStore, SessionStorageStore } from './stores';

// prettier-ignore
export const useLocalStorageStore = <T,>(key: string) => {
  const [selector, update, remove] = useMemo(
    () => [
      (): T | undefined => LocalStorageStore.get<T>(key),
      (v: T) => LocalStorageStore.update<T>(key, v),
      () => LocalStorageStore.remove(key),
    ],
    [key]
  );

  const value = useSyncExternalStore(LocalStorageStore.subscribe, selector);

  return [value, update, remove] as const;
};

// prettier-ignore
export const useLocalStorageStoreActions = <T,>(key: string) => useMemo(
    () => [
      (v: T) =>
        LocalStorageStore.update(key, v),
      () =>
        LocalStorageStore.remove(key),
    ],
    [key]
  );

// prettier-ignore
export const useSessionStorageStore = <T,>(key: string) => {
  const [selector, update, remove] = useMemo(
    () => [
      (): T | undefined => SessionStorageStore.get<T>(key),
      (v: T) => SessionStorageStore.update<T>(key, v),
      () => SessionStorageStore.remove(key),
    ],
    [key]
  );

  const value = useSyncExternalStore(SessionStorageStore.subscribe, selector);

  return [value, update, remove] as const;
};

// prettier-ignore
export const useSessionStorageStoreActions = <T,>(key: string) => useMemo(
    () => [
      (v: T) =>
        SessionStorageStore.update(key, v),
      () =>
        SessionStorageStore.remove(key),
    ],
    [key]
  );

// prettier-ignore
export const useCookie = <T,>(cookieName: string) => {
  const [selector, update, remove] = useMemo(
    () => [
      (): T | undefined => CookieStore.get<T>(cookieName),
      (v: T, options?: Cookies.CookieAttributes) =>
        CookieStore.update<T, Cookies.CookieAttributes>(cookieName, v, options),
      (options?: Cookies.CookieAttributes) =>
        CookieStore.remove(cookieName, options),
    ],
    [cookieName]
  );

  const value = useSyncExternalStore(CookieStore.subscribe, selector);

  return [value, update, remove] as const;
};

// prettier-ignore
export const useCookieActions = <T,>(cookieName: string) =>
  useMemo(
    () => [
      (v: T, options?: Cookies.CookieAttributes) =>
        CookieStore.update<T, Cookies.CookieAttributes>(cookieName, v, options),
      (options?: Cookies.CookieAttributes) =>
        CookieStore.remove(cookieName, options),
    ],
    [cookieName]
  );
