import Cookies from 'js-cookie';
import { createBrowserStore } from './createBrowserStore';

const safeJsonParse = (data: any) => {
  if (typeof data === 'string') {
    try {
      return JSON.parse(data);
    } catch (e) {
      if (data) return data;
      return undefined;
    }
  }
  return undefined;
};

const prepareValueToStore = (value: any) => {
  if (typeof value === 'object' && !Array.isArray(value)) {
    return JSON.stringify(value);
  }
  return value;
};

export const CookieStore = createBrowserStore({
  name: 'cookies',
  options: { withCache: false },
  get: (key: string) => {
    try {
      const item = Cookies.get(key);
      return safeJsonParse(item);
    } catch (error) {
      return undefined;
    }
  },
  set: (key, value, opts) => {
    const valueToStore = prepareValueToStore(value);
    const options: Cookies.CookieAttributes = opts ?? {
      domain: window.location.hostname.split('.').slice(-2).join('.'),
      secure: true,
      sameSite: 'none',
      path: '/',
    };
    Cookies.set(key, valueToStore, options);
  },
  delete: (key, opts) => {
    const options: Cookies.CookieAttributes = opts ?? {
      domain: window.location.hostname.split('.').slice(-2).join('.'),
      secure: true,
      sameSite: 'none',
      path: '/',
    };
    Cookies.remove(key, options);
  },
});

export const LocalStorageStore = createBrowserStore({
  name: 'localStorage',
  get: (key) => {
    const item = window.localStorage.getItem(key);
    return safeJsonParse(item);
  },
  set: (key, value) => {
    const valueToStore = prepareValueToStore(value);
    window.localStorage.setItem(key, valueToStore);
  },
  delete: (key) => {
    window.localStorage.removeItem(key);
  },
});

export const SessionStorageStore = createBrowserStore({
  name: 'sessionStorage',
  get: (key) => {
    const item = window.sessionStorage.getItem(key);
    return safeJsonParse(item);
  },
  set: (key, value) => {
    const newValue = prepareValueToStore(value);
    window.sessionStorage.setItem(key, newValue);
  },
  delete: (key) => {
    window.sessionStorage.removeItem(key);
  },
});
