type StoreOptions = {
  withCache: boolean;
};

interface BrowserStoreConfig {
  name: string;
  options?: StoreOptions;
  get: <T>(key: string) => T | undefined;
  set: <T, K>(key: string, v: T, opt?: K) => void;
  delete: <K>(key: string, opt?: K) => void;
}

type Listener = () => void;

const defaultOptions: StoreOptions = {
  withCache: true,
};

export const createBrowserStore = (store: BrowserStoreConfig) => {
  const options = store.options ?? defaultOptions;
  const cache = new Map<string, any>();
  const listeners = new Set<Listener>();

  if (typeof window === 'undefined') {
    throw new Error(`Failed to create browserStore: ${store.name}`);
  }

  const subscribe = (listener: Listener) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };

  const notify = () => listeners.forEach((l) => l());

  const get = <T,>(storageKey: string): T | undefined => {
    if (options.withCache) {
      const value = cache.get(storageKey);
      if (value) return value;
    }

    const storeValue = store.get<T>(storageKey);
    if (!storeValue) return undefined;

    if (options.withCache) {
      cache.set(storageKey, storeValue);
    }

    return storeValue;
  };
  const update = <T, K = undefined>(storageKey: string, v: T, opts?: K) => {
    if (options.withCache) {
      cache.set(storageKey, v);
    }
    store.set(storageKey, v, opts);
    notify();
  };
  const remove = <K = undefined>(storageKey: string, opts?: K) => {
    if (options.withCache) {
      cache.delete(storageKey);
    }
    store.delete(storageKey, opts);
    notify();
  };

  return {
    subscribe,
    get,
    update,
    remove,
  };
};
